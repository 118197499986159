export const fbConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
        apiKey: 'AIzaSyAqRT2LgXAmoEbDJpVl2OXj-gQfV74DnZg',
        authDomain: 'hypercubes.firebaseapp.com',
        databaseURL: 'https://hypercubes.firebaseio.com',
        projectId: 'project-2679562374362981399',
        storageBucket: 'project-2679562374362981399.appspot.com',
        messagingSenderId: '710388098352',
        appId: '1:710388098352:web:7d29fe08b1c45c07c394f5',
      }
    : {
        apiKey: 'AIzaSyAog3DJYLedxXdUcOTfJZO8UyQgh75UmrU',
        authDomain: 'cube-dev-e4a5d.firebaseapp.com',
        databaseURL:
          'https://cube-dev-e4a5d-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'cube-dev-e4a5d',
        storageBucket: 'cube-dev-e4a5d.appspot.com',
        messagingSenderId: '742690200178',
        appId: '1:742690200178:web:520117fad3b900c19180eb',
        measurementId: 'G-ZVPHBMSH7F',
      }
