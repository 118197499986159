import { useCookies } from 'react-cookie'

import { Box, Button, Snackbar, Typography } from '@mui/material'
import { fbConfig } from '@Config/fbConfig'

export function CookieConsent() {
  const { measurementId } = fbConfig
  const [cookies, setCookie] = useCookies(['cookieConsent'])

  const handleConsent = (consent) => {
    if (!measurementId) {
      console.error('Google Analytics Measurement ID is not defined')
      return
    }

    const oneYear = 365 * 24 * 60 * 60 // 1 year in seconds
    setCookie('cookieConsent', consent, { path: '/', maxAge: oneYear })
    window[`ga-disable-${measurementId}`] = consent === 'declined'
  }

  if (cookies.cookieConsent) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={true}
      message={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            We use cookies to improve your experience. Do you accept?
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleConsent('accepted')}
              sx={{ mr: 1 }}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleConsent('declined')}
            >
              Decline
            </Button>
          </Box>
        </Box>
      }
    />
  )
}
