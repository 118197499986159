import { StrictMode, Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'

import printBuildInfo from '@Core/utils/printBuildInfo'

import { LoadingIcon } from '@Routes/system/LoadingIcon'

import { CookieConsent } from '@Components/ui/CookieConsent'

const App = lazy(() => import('./App'))
const ProviderWrapper = lazy(() => import('./ProviderWrapper')) // Wrapped so we can Lazyload

printBuildInfo()
// console.log('env', process.env);
const container = document.getElementById('root') as Element
const root = createRoot(container)

root.render(
  // <StrictMode>
  <Suspense fallback={<LoadingIcon />}>
    <ProviderWrapper>
      <Suspense fallback={<LoadingIcon />}>
        <App />
        <CookieConsent />
      </Suspense>
    </ProviderWrapper>
  </Suspense>
  // </StrictMode>
  // document.getElementById('root')
)

// YT API
// handleClientLoad()
